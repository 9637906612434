import AddIcon from "@mui/icons-material/Add";
import { Alert, Checkbox, Modal, Snackbar, Stack, TableHead, TableSortLabel, Typography } from "@mui/material";
import React, { useEffect, useMemo, useState } from "react";
import TopMenu from "../../../components/TopMenu";

//styles modules
/* import "./style.css"; */
import styles from "./RequestsCad.module.css";

//material ul imports
import { useTheme } from "@emotion/react";
import FirstPageIcon from "@mui/icons-material/FirstPage";
import KeyboardArrowLeft from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRight from "@mui/icons-material/KeyboardArrowRight";
import LastPageIcon from "@mui/icons-material/LastPage";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import IconButton from "@mui/material/IconButton";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import { ptBR } from "@mui/material/locale";
import { ThemeProvider, createTheme, styled } from "@mui/material/styles";
import { visuallyHidden } from "@mui/utils";
import PropTypes from "prop-types";
import HelpButtonInput from "../../../components/HelpButtonInput";

import { Autocomplete } from "@mui/material";

const theme = createTheme(
	{
		palette: {
			primary: { main: "#1976d2" },
		},
	},
	ptBR
);

const Item = styled(Paper)(({ theme }) => ({
	backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
	...theme.typography.body2,
	padding: theme.spacing(1),
	textAlign: "center",
	color: theme.palette.text.secondary,
}));
const stylesItem = {
	bgcolor: "rgba(0, 0, 0, 0)",
	border: "none",
	boxShadow: "none",
	display: "flex",
	alignItems: "center",
	justifyContent: "start",
	width: "100%",
	m: 0,
	p: 0,
	minHeight: "45px",
	minWidth: "40vw",
};

//components
import InputData from "../../../components/InputData";
import RegisterArea from "../../../components/RegistrationArea";
import { captalizeFirstLetterEacheWord } from "../../../utils/utils";
import { CustomerService } from "../../../service/customer_service";
import { FarmService } from "../../../service/farm_service";
import { HarvestService } from "../../../service/harvest_service";
import { OrderService } from "../../../service/order_service";
import Loading from "../../../components/Loading";

const style = {
	position: "absolute",
	top: "50%",
	left: "50%",
	transform: "translate(-50%, -50%)",
};
const styleAdd = {
	color: "#1A877F",
	margin: "0 0 0 5px",
};
function TablePaginationActions(props) {
	const theme = useTheme();
	const { count, page, rowsPerPage, onPageChange } = props;

	const handleFirstPageButtonClick = (event) => {
		onPageChange(event, 0);
	};

	const handleBackButtonClick = (event) => {
		onPageChange(event, page - 1);
	};

	const handleNextButtonClick = (event) => {
		onPageChange(event, page + 1);
	};

	const handleLastPageButtonClick = (event) => {
		onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
	};

	return (
		<Box sx={{ flexShrink: 0, ml: 2.5 }}>
			<IconButton
				onClick={handleFirstPageButtonClick}
				disabled={page === 0}
				aria-label="first page"
			>
				{theme.direction === "rtl" ? <LastPageIcon /> : <FirstPageIcon />}
			</IconButton>
			<IconButton
				onClick={handleBackButtonClick}
				disabled={page === 0}
				aria-label="previous page"
			>
				{theme.direction === "rtl" ? <KeyboardArrowRight /> : <KeyboardArrowLeft />}
			</IconButton>
			<IconButton
				onClick={handleNextButtonClick}
				disabled={page >= Math.ceil(count / rowsPerPage) - 1}
				aria-label="next page"
			>
				{theme.direction === "rtl" ? <KeyboardArrowLeft /> : <KeyboardArrowRight />}
			</IconButton>
			<IconButton
				onClick={handleLastPageButtonClick}
				disabled={page >= Math.ceil(count / rowsPerPage) - 1}
				aria-label="last page"
			>
				{theme.direction === "rtl" ? <FirstPageIcon /> : <LastPageIcon />}
			</IconButton>
		</Box>
	);
}

TablePaginationActions.propTypes = {
	count: PropTypes.number.isRequired,
	onPageChange: PropTypes.func.isRequired,
	page: PropTypes.number.isRequired,
	rowsPerPage: PropTypes.number.isRequired,
};

function EnhancedTableHead(props) {
	const {onSelectAllClick, order, orderBy, numSelected, rowCount, onRequestSort,columns} =
    props;
	const createSortHandler = (property) => (event) => {
		onRequestSort(event, property);
	};

	return (
		<TableHead className={styles.tableGenerator}>			
			<TableRow>
				<TableCell padding="checkbox">
					<Checkbox
						color="primary"
						indeterminate={numSelected > 0 && numSelected < rowCount}
						checked={rowCount > 0 && numSelected === rowCount}
						onChange={onSelectAllClick}
						inputProps={{
							"aria-label": "select all desserts",
						}}
					/>
				</TableCell>
				{columns.map((headCell) => (
					<TableCell
						key={headCell.id}
						align={ "left"}
						padding={ "normal"}
						sortDirection={orderBy === headCell.id ? order : false}
					>
						<TableSortLabel
							active={orderBy === headCell.id}
							direction={orderBy === headCell.id ? order : "asc"}
							onClick={createSortHandler(headCell.id)}
						>
							{headCell.label}
							{orderBy === headCell.id ? (
								<Box component="span" sx={visuallyHidden}>
									{order === "desc" ? "sorted descending" : "sorted ascending"}
								</Box>
							) : null}
						</TableSortLabel>
					</TableCell>
					
				))}
				<TableCell></TableCell>
			</TableRow>
		</TableHead>
	);
}

EnhancedTableHead.propTypes = {
	numSelected: PropTypes.number.isRequired,
	onRequestSort: PropTypes.func.isRequired,
	onSelectAllClick: PropTypes.func.isRequired,
	order: PropTypes.oneOf(["asc", "desc"]).isRequired,
	orderBy: PropTypes.string.isRequired,
	rowCount: PropTypes.number.isRequired,
};
const columnForActiveLote = [
	{
		id: "cropSeason",
		label:"Safras",
	},
	{
		id: "harvest",
		label:"Lote",
	},
	{
		id: "date",
		label:"Data",
	},
	{
		id: "disponible",
		label:"Quantidade disponivel",
	},
	{
		id: "quantity",
		label:"Quantidade",
	},
];

function RequestsCad() {
	const [order, setOrder] = useState({
		items: []
	});
	const [allCustomers, setAllCustomers] = useState([]);
	const [allFarms, setAllFarms] = useState([]);
	const [orderItem, setOrderItem] = useState({harvests: []});
	const [harvests, setHarvests] = useState([]);
	const [errorAddHarvest, setErrorAddHarvest] = React.useState(false);
	const [open, setOpen] = useState(false);
	const [openAlertModal, setOpenAlertModal] = useState(false);
	// const [searchParams] = useSearchParams();
	
	const [openLote, setOpenLote] = useState(false);
	const [valorTotal, setValorTotal] = useState(0);
	const [message, setMessage] = useState("");
	const [typeMessage, setTypeMessage] = useState("info");
	const [errorMessageInputCultura, setErrorMessageInputCultura] =
		useState(false);
	const [errorMessageInputEmbalagem, setErrorMessageInputEmbalagem] =
		useState(false);
	const [errorMessageSelectCliente, setErrorMessageSelectCliente] =
		useState(false);
	const [errorMessageDataEntrega, setErrorMessageDataEntrega] = useState(false);
	const [errorMessageItems, setErrorMessageItems] = useState(false);
	const [errorMessageLote, setErrorMessageLote] = useState(false);
	const keysForActiveLote = columnForActiveLote; //array de chaves
	const [page, setPage] = React.useState(0); //paginação
	const [rowsPerPage, setRowsPerPage] = React.useState(7); //paginação
	const [selected, setSelected] = useState([]);
	const [orderBy, setOrderBy] = useState("asc");
	const [orderByColumn, setOrderByColumn] = useState(keysForActiveLote[0].id);
	const [isLoading, setIsLoading] = useState(true);

	const customerService = new CustomerService();
	const farmService = new FarmService();
	const harvestService = new HarvestService();
	const orderService = new OrderService();

	const handleRequestSort = (event, property) => {
		const isAsc = orderByColumn === property && orderBy === "asc";
		setOrderBy(isAsc ? "desc" : "asc");
		setOrderByColumn(property);
	};

	const handleSelectAllClicks = (event) => {
		if (event.target.checked) {
			const newSelected = harvests.map((n) => n.name);
			setSelected(newSelected);
			return;
		}
		setSelected([]);
	};

	useEffect(() => {
		initData();
	}, []);

	const initData = async () => {
		const propertyId = localStorage.getItem("propertyId");
		await getAllCustomers(propertyId);
		await getAllFarms(propertyId);
		setIsLoading(false);
	};

	const formatDate = (dateToFormat) => {
		const date = new Date(dateToFormat);
		return (
			("0" + date.getUTCDate()).slice(-2) +
			"/" +
			("0" + (date.getUTCMonth() + 1)).slice(-2) +
			"/" +
			date.getUTCFullYear()
		);
	};

	const getHarvests = async (farmId, measurementUnityId) => {
		const propertyId = localStorage.getItem("propertyId");
		const params = {
			page: 1,
			limit: 100000,
			filterValue: encodeURI(
				JSON.stringify(
					{ 
						pendente: false, 
						previsao: false,
						propriedadeCultivoId: farmId,
						embalagem: {
							unidadeMedidaID: measurementUnityId
						} 
					}
				)
			),
			orderBy: JSON.stringify(
				{
					createdAt: "desc"
				}
			)
		};

		let propertyHavests = await harvestService.getByProperty(propertyId, params);
		propertyHavests = propertyHavests.harvests;

		const harvestsToShow = [];
		propertyHavests.forEach((propertyHarvest) => {
			const harvestQuantity = getHarvestQuantity(propertyHarvest);
			if(harvestQuantity > 0) {
				harvestsToShow.push({
					id: propertyHarvest.id,
					cropSeason: getCropSeasonsName(propertyHarvest.loteSafras),
					harvest: propertyHarvest.codigo,
					date: formatDate(propertyHarvest.dataColheita),
					disponible: harvestQuantity,
					quantity: (
						<div id={propertyHarvest.id + "controlError"}>
							<input
								id={propertyHarvest.id + "-quantity"}
								type={"number"}
								placeholder={"Quantidade"}
							/>
						</div>
					),
				});
			}
		});
		setHarvests(harvestsToShow);
	};

	const getHarvestQuantity = (harvest) => {
		let entryQuantity = 0;
		let outputQuantity = 0;
		let lossQuantity = 0;

		harvest.loteSafras.forEach((cropSeasonOnHarvest) => {
			entryQuantity += cropSeasonOnHarvest.quantidade;
		});

		harvest.itemPedidos.forEach((orderItemOnHarvest) => {
			if(!orderItemOnHarvest.devolvido)
				outputQuantity += orderItemOnHarvest.quantidade;
		});

		harvest.perdas.forEach((lossOnLot) => {
			lossQuantity += lossOnLot.quantidade;
		});

		return entryQuantity - (lossQuantity + outputQuantity);
	};

	const getCropSeasonsName = (cropSeasonList) => {
		let name = "";
		cropSeasonList.forEach((cropSeason) => {
			name += getCropSeasonName(cropSeason.safra) + ", ";
		});
		return name;
	};

	const getCropSeasonName = (cropSeason) => {
		const plot = cropSeason.talhao;
		let cropSeasonName = `Talhão ${plot.nome}`;
		if (cropSeason.subdivisao) {
			const subdivision = cropSeason.subdivisao;
			cropSeasonName += ` - ${subdivision.categoriaSubdivisao} ${subdivision.complemento}`;
		}
		const propertyFarm = cropSeason.propriedadeCultivo;
		const farm = propertyFarm.cultivo;
		cropSeasonName += ` - ${captalizeFirstLetterEacheWord(farm.cultura)} ${captalizeFirstLetterEacheWord(farm.variedade ?? "")}`;
		cropSeasonName += ` - ${new Date(cropSeason.dataPlantio).toLocaleDateString("pt-BR")}`;
		return cropSeasonName;
	};

	const getAllCustomers = async (propertyId) => {
		const customerParams = {
			page: 1,
			limit: 100000,
			sortBy: JSON.stringify([
				{
					nome: "asc"
				}
			])
		};
		const customersInProperty = await customerService.getByProperty(customerParams, propertyId);
		setAllCustomers(customersInProperty);
	};

	const getAllFarms = async (propertyId) => {
		const farmParams = {
			page: 1,
			limit: 100000,
			sortBy: JSON.stringify([
				{
					cultivo: {
						cultura: "asc"
					}
				},
				{
					cultivo: {
						variedade: "asc"
					}
				}
			])
		};
		const farmsInProperty = await farmService.get(farmParams, propertyId);
		const farmsToShow = farmsInProperty.farms.map((farm) => {
			const title = captalizeFirstLetterEacheWord(`${farm.cultivo.cultura} ${farm.cultivo?.variedade ?? ""}`);
			const firstChar = title[0].toUpperCase();
			const firstLetter = /[0-9]/.test(firstChar) ? "0-9" : firstChar;
			
			return {
				id: farm.id,
				title: title,
				firstLetter: firstLetter,
				embalagens: farm.embalagens.map((packageFarm) => {
					return {
						...packageFarm,
						label: `${packageFarm.nome} (${packageFarm.quantidade} ${packageFarm.unidadeMedida.sigla.toUpperCase()})`,
					};
				}),
			};
		});
	
		setAllFarms(farmsToShow);
	};

	const somatoria = (harvestsToCalc) => {
		let soma = 0;
		harvestsToCalc.map((item) => {
			if (item.preco && item.preco !== 0){
				item.lotes.forEach((harvestItem) => {
					soma += item.preco * harvestItem.quantidade;
				});
			}
				
		});
		setValorTotal(soma);
	};

	const handleAdd = () => {
		const newOrderItem = {
			propriedadeCultivo: orderItem.propriedadeCultivo,
			embalagem: orderItem.embalagem,
			dataEmbalagem: orderItem.dataEmbalagem,
			preco: orderItem.preco,
			lotes: orderItem.harvests
		};

		const orderItems = [...order.items, newOrderItem];
		setOrder({...order, items: orderItems});
		somatoria(orderItems);
	};
	
	const handleRemove = (index) => {
		const newItems = order.items.filter((item, itemIndex) => itemIndex !== index);

		setOrder({...order, items: newItems});
		somatoria(newItems);
	};
	const removeModalHarvest = (index) => {
		const harvestsToKeep = orderItem.harvests.filter((item, itemIndex) => itemIndex !== index);

		setOrderItem({...orderItem, harvests: harvestsToKeep});
		somatoria(harvestsToKeep);
	};
	const handleRemoveAll = () => {
		setOrder({...order, items: []});
		setOrderItem({harvests: []});
		// setInputLote("");
		// setInputQuantidadeLote("");
		setValorTotal(0);
		handleClose();
		setErrorMessageInputCultura(false);
		setErrorMessageInputEmbalagem(false);
	};
	const handleOpen = () => {
		setOpen(true); //abre o modal
	};
	const handleClose = () => {
		setOpen(false); //fecha o modal
		setErrorMessageInputCultura(false);
		setErrorMessageInputEmbalagem(false);
	};

	const validationSaveModal = () => {
		if (
			orderItem.propriedadeCultivo &&
			orderItem.embalagem
		) {
			handleAdd(); //adiciona o item no array
			setOpen(false); //fecha o modal
			setOrderItem({harvests: []});
		} else {
			let message = "Um ou mais campos vazios. - (";
			if (orderItem.propriedadeCultivo === null) {
				message += "Selecione a cultura, ";
				setErrorMessageInputCultura(true);
			} else {
				setErrorMessageInputCultura(false);
			}

			if (orderItem.embalagem === null) {
				message += "Selecione a embalagem, ";
				setErrorMessageInputEmbalagem(true);
			} else {
				setErrorMessageInputEmbalagem(false);
			}

			message += ")";
			setTypeMessage("error");
			setMessage(message);
			setOpenAlertModal(true);
		}
	};
	
	const handleCloseBtnAdd = () => {
		validationSaveModal();
	};
	
	const validationSave = async () => {
		let isValidPackageDate = true;
		order.items.forEach((item) => {
			isValidPackageDate = isValidPackageDate && item.dataEmbalagem !== null && item.dataEmbalagem !== undefined;
		});
		if (
			order.clienteId &&
			order.clienteId !== "-1" &&
			order.data &&
			order.items.length > 0 &&
			isValidPackageDate
		) {
			await handleSave();
			setTypeMessage("success");
			setMessage("cadastro salvo!!!");
			return true;
		} else {
			let message = "Um ou mais campos vazios. - (";
			if (!order.clienteId || order.clienteId === "-1") {
				message += "Selecione o cliente, ";
				setErrorMessageSelectCliente(true);
			} else {
				setErrorMessageSelectCliente(false);
			}

			if (!order.data) {
				message += "Data de expedição, ";
				setErrorMessageDataEntrega(true);
			} else {
				setErrorMessageDataEntrega(false);
			}
			console.log(order.items);
			if (order.items.length === 0) {
				message += "Adicionar cultura/variedade, ";
				setErrorMessageItems(true);
			} else {
				setErrorMessageItems(false);
			}

			if (!isValidPackageDate)
				message += "Um ou mais itens sem data de embalagem.";

			message += ")";
			setTypeMessage("error");
			setMessage(message);
		}
	};

	const handleSave = async () => {

		const orderItems = order.items.map((item) => {
			const itemHarvests = item.lotes.map((harvest)=> {
				return {
					id: harvest.id,
					stockAmount: harvest.quantidade
				};
			});
			
			return {
				farmId: item.propriedadeCultivo.id,
				lotObject: itemHarvests,
				packagingDate: item.dataEmbalagem,
				packagingId: item.embalagem.id,
				price: Number(item.preco),
			};
		});

		const orderToSave = {
			clientId: order.clienteId,
			observation: order.observacao,
			date: order.data,
			invoice: order.nota_fiscal,
			farmObject: orderItems
		};

		await orderService.save(orderToSave);
	};

	const handleClick = (e, name) => {
		const selectedIndex = selected.indexOf(name);
		let newSelected = [];

		if (selectedIndex === -1) {
			newSelected = newSelected.concat(selected, name);
		} else if (selectedIndex === 0) {
			newSelected = newSelected.concat(selected.slice(1));
		} else if (selectedIndex === selected.length - 1) {
			newSelected = newSelected.concat(selected.slice(0, -1));
		} else if (selectedIndex > 0) {
			newSelected = newSelected.concat(
				selected.slice(0, selectedIndex),
				selected.slice(selectedIndex + 1)
			);
		}

		setSelected(newSelected);
	};

	const isSelected = (name) => selected.indexOf(name) !== -1;
	
	const handleCloseAlertModal = (event, reason) => {
		if (reason === "clickaway") return;

		setOpenAlertModal(false);
	};
	
	const handleOpenLote = () => {
		setOpenLote(true); //abre o modal
		setErrorMessageLote(false);
	};
	// function setLote(loteId) {
	// 	setInputLote(loteId);
	// }
	const handleCloseLote = () => {
		setOpenLote(false); //fecha o modal
		setErrorMessageLote(false);
	};

	const registerModalHarvests = async () => {
		console.log("teste");
		let Aux = 0;
		let AuxError = 0;
		for (let j = 0; j < selected.length; j++) {
			let quantidade = "";
			for (let i = 0; i < harvests.length; i++) {
				if (harvests[i].id === selected[j]) {
					quantidade = Number(document.getElementById(
						harvests[i].id + "-quantity"
					).value);
					if (quantidade > harvests[i].disponible) {
						let getElementById = document.getElementById(
							harvests[i].id + "controlError"
						);
						getElementById.className = styles.errControl;
						AuxError += 1;
						
						const selectedInput = document.getElementById(
							harvests[i].id + "-quantity"
						);
						selectedInput.focus();
						setErrorMessageLote(true);
					}
					if (quantidade <= 0) {
						let getElementById = document.getElementById(
							harvests[i].id + "controlError"
						);
						getElementById.className = styles.errControl;

						const selectedInput = document.getElementById(
							harvests[i].id + "-quantity"
						);
						selectedInput.focus();
						AuxError += 1;
					} else {
						let getElementById = document.getElementById(
							harvests[i].id + "controlError"
						);
						getElementById.className = "";

						Aux += 1;
					}
				}
			}
		}
		if (Aux > 0 && AuxError <= 0) {
			addHarvests(); //adiciona o item no array
			setOpenLote(false); //fecha o modal
			setErrorAddHarvest(false);
		} else {
			if (selected.length === 0) setErrorAddHarvest(true);
			else setErrorAddHarvest(false);
		}
	};

	const addHarvests = () => {
		for (let j = 0; j < selected.length; j++) {
			let quantity = 0;
			for (let i = 0; i < harvests.length; i++) {
				if (harvests[i].id === selected[j]) {
					quantity = Number(document.getElementById(
						harvests[i].id + "-quantity").value
						.replace(",", "."));
					const harvest = harvests[i];
					const harvestToAdd = {
						id: harvest.id,
						codigo: harvest.harvest,
						quantidade: quantity,
					};
					orderItem.harvests.push(harvestToAdd);
					break;
				}
			}
			selected[j] = false;
		}
		return;
	};
	
	const handleChangePage = (event, newPage) => {
		setPage(newPage); //paginação
	};

	const handleChangeRowsPerPage = (event) => {
		setRowsPerPage(+event.target.value); //paginação
		setPage(0);
	};

	const getItemsTotalQuantity = (items) => {
		let total = 0;
		items.forEach((item) => total += item.quantidade);
		return total;
	};

	function descendingComparator(a, b, orderBy) {
		if (b[orderBy] < a[orderBy]) 
			return -1;
  
		if (b[orderBy] > a[orderBy]) 
			return 1;
  
		return 0;
	}
	function stableSort(array, comparator) {
		const stabilizedThis = array.map((el, index) => [el, index]);
		stabilizedThis.sort((a, b) => {
			const order = comparator(a[0], b[0]);
			if (order !== 0) 
				return order;
    
			return a[1] - b[1];
		});
		return stabilizedThis.map((el) => el[0]);
	}
	function getComparator(order, orderBy) {
		return order === "desc"
			? (a, b) => descendingComparator(a, b, orderBy)
			: (a, b) => -descendingComparator(a, b, orderBy);
	}
	const visibleRows = useMemo(
		() =>
			stableSort(harvests, getComparator(orderBy, orderByColumn)),
		[orderBy, orderByColumn, page, rowsPerPage, harvests]
	);
	return isLoading ? 
		(
			<div>
				<Loading /> 
			</div>
		) : (
			<div>
				<TopMenu open={["launch", "requests"]} />
				<Box component="main" sx={{ width: "100vw", p: 0, mt: 12 }}>
					<Box sx={{ width: "100%", px: 3 }}>
						<Grid container rowSpacing={3} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
							<Grid item xs={12} sm={12} md={12}>
								<Item
									sx={{
										border: "none",
										boxShadow: "none",
										display: "flex",
										alignItems: "stretch",
										justifyContent: "stretch",
										widths: "auto",
										m: 0,
										p: 0,
										minHeight: "45px",
										minWidth: "40vw",
									}}
								>
									<RegisterArea
										title="Lançamento de Pedido"
										href="/pedidos"
										salveFunc={validationSave}
										typeMessage={typeMessage}
										message={message}
										help="requestsCad"
									>
										<Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
											<Grid item xs={12} sm={4} md={4}>
												<Box Box sx={{
													border: "1px solid rgba(0,0,0,0.3)",
													borderRadius: "8px",
													width: "100%",
													display: "flex",
													justifyContent: "flex-start",
													flexDirection: "column",
													alignItems: "flex-start",
													px: 1,
													pr:2,
													position: "relative",
													m:0
												}}>
													<div
														className={
															errorMessageSelectCliente
																? styles.errControl
																: styles.noErrControl
														}
													>
														<Typography variant="caption" display="block" gutterBottom sx={{top:-11,position:"absolute",px:1,backgroundColor:"white",visibility:(order.clienteId ? "visible" : "hidden"),transition:"all 0.5s ease"}} >
																Selecione o cliente *
														</Typography>
		
														<p
															className={
																errorMessageSelectCliente ? styles.errText : styles.noErrText
															}
														>
															*
														</p>
														<select
															id="client"
															onChange={(e) => setOrder({...order, clienteId: e.currentTarget.value})}
															className={styles.inputRequest}
														>
															<option value={"-1"}>
																{order.clienteId
																	? "--------------- *"
																	: "Selecione o cliente *"}
															</option>
															{allCustomers.map((item, index) => (
																<option key={index} value={item.id}>
																	{item.nome}
																</option>
															))}
														</select>
													</div>
												</Box>
												
											</Grid>
											<Grid item xs={12} sm={4} md={4}>
												<Box	Box sx={{
													border: "1px solid rgba(0,0,0,0.3)",
													borderRadius: "8px",
													width: "100%",
													display: "flex",
													justifyContent: "flex-start",
													flexDirection: "column",
													alignItems: "flex-start",
													px: 1,
													pr:2,
													position: "relative",
													m:0
												}}>
													<Typography 
														variant="caption" 
														display="block" 
														gutterBottom 
														sx={{
															top:-11,
															position:"absolute",
															px:1,
															backgroundColor:"white",
															visibility:(order.data ? "visible" : "hidden"),
															transition:"all 0.5s ease"
														}} 
													>
														Data de expedição *
													</Typography>
													<div
														className={
															errorMessageDataEntrega ? styles.errControl : styles.noErrControl
														}
													>
														<p
															className={
																errorMessageDataEntrega ? styles.errText : styles.noErrText
															}
														>
															*
														</p>
														<InputData placeholder="Data de expedição *" onDateChange={(date) => setOrder({...order, data: date})} classN={styles.inputRequest}/>
													</div>
												</Box>
											</Grid>
											<Grid item xs={12} sm={4} md={4}>
												<Box	Box sx={{
													border: "1px solid rgba(0,0,0,0.3)",
													borderRadius: "8px",
													width: "100%",
													display: "flex",
													justifyContent: "flex-start",
													flexDirection: "column",
													alignItems: "flex-start",
													px: 1,
													pr:2,
													position: "relative",
													m:0
												}}>
													<Typography variant="caption" display="block" gutterBottom sx={{top:-11,position:"absolute",px:1,backgroundColor:"white",visibility:(order.nota_fiscal ? "visible" : "hidden"),transition:"all 0.5s ease"}} >
														Nota fiscal
													</Typography>
													<input
														className={styles.inputRequest}
														type="text"
														placeholder="Nota fiscal"
														value={order.nota_fiscal}
														onChange={(e) => setOrder({...order, nota_fiscal: e.target.value})}
													/>
												</Box>
											</Grid>
											<Grid item xs={12} sm={12} md={12}>
												<Box	Box sx={{
													border: "1px solid rgba(0,0,0,0.3)",
													borderRadius: "8px",
													width: "100%",
													display: "flex",
													justifyContent: "flex-start",
													flexDirection: "column",
													alignItems: "flex-start",
													px: 1,
													pr:2,
													position: "relative",
													m:0
												}}>
													<Typography variant="caption" display="block" gutterBottom sx={{top:-11,position:"absolute",px:1,backgroundColor:"white",visibility:(order.observacao ? "visible" : "hidden"),transition:"all 0.5s ease"}} >
														Observações
													</Typography>
													<input
														className={styles.inputRequest}
														type="text"
														placeholder="Observações"
														value={order.observacao}
														onChange={(e) => setOrder({...order, observacao: e.target.value})}
													/>
												</Box>
											</Grid>
											<Grid item xs={12} sm={12} md={12}>
												<div className={styles.registrationTitle}>
													<a onClick={handleOpen} className={styles.addBtn}>
															Adicionar cultura/variedade *
														<AddIcon style={styleAdd} />
													</a>
												</div>
												<p
													className={errorMessageItems ? styles.errText : styles.noErrText}
												>
														Adicione 1 ou mais culturas/variedades
												</p>
											</Grid>
											<Grid item xs={12} sm={12} md={12}>
												<Item sx={stylesItem}>
													<Grid item xs={12} sm={12} md={12}>
														<Item sx={{ minHeight: "45px", minWidth: "50vw" }}>
															<Grid
																container
																rowSpacing={1}
																columnSpacing={{ xs: 1, sm: 2, md: 3 }}
																sx={{
																	display: "flex",
																	alignItems: "center",
																	justifyContent: "start",
																	width: "100%",
																}}
															>
																{order.items.map((item, index) => (
																	<Grid item key={index}>
																		<Item sx={{ border: "none", boxShadow: "none" }}>
																			<div className={styles.itemNameCuture}>
																				<button
																					className={styles.itemButtonCuture}
																					type="button"
																					id={item.itemId}
																					onClick={() => handleRemove(index)}
																				>
																					X
																				</button>
																				{`${item.propriedadeCultivo.title} - ${getItemsTotalQuantity(item.lotes).toString().replace(".", ",")}`}
																			</div>
																		</Item>
																	</Grid>
																))}
															</Grid>
														</Item>
													</Grid>
												</Item>
											</Grid>
											<Grid item xs={12} sm={12} md={12}>
												<Item sx={stylesItem}>
													{valorTotal > 0 ? (
														<div className={styles.registerAreaValor}>
															<p>
																R$
																{valorTotal.toFixed(2).toString().replace(".", ",")}{" "}
															</p>
														</div>
													) : (
														""
													)}
												</Item>
											</Grid>
										</Grid>
									</RegisterArea>
								</Item>
							</Grid>
						</Grid>
					</Box>
				</Box>
				{/* modalContent */}
				<Modal
					style={{zIndex: 100}}
					open={open}
					onClose={handleClose}
					aria-labelledby="parent-modal-title"
					aria-describedby="parent-modal-description"
				>
					<Box
						sx={{
							...style,
							width: { xs: "90%", sm: "50%", md: "50%" },
							bgcolor: "white",
							borderRadius: "8px",
							p: 3,
							m: 0,
							overflowY: "auto",
							maxHeight: "85vh",
						}}
					>
						<Grid
							container
							rowSpacing={2}
							columnSpacing={{ xs: 1, sm: 2, md: 3 }}
							className={styles.registrationInputModal}
							display="flex"
							justifyContent="center"
							alignItems="ccenter"
							width={"auto"}
							padding={0}
							margin={0}
						>
							<Grid item xs={12} sm={12} md={12}>
								<p className={styles.registrationTitle}>
									Cultura/variedade
								</p>
							</Grid>
							<Grid item xs={12} sm={12} md={12}>
								<Grid
									container
									rowSpacing={4}
									columnSpacing={{ xs: 1, sm: 2, md: 3 }}
									className={styles.registrationInputModal}
								>
									<Grid item xs={12} sm={6} md={6}>
										<Box	Box sx={{
											width: "100%",
											display: "flex",
											justifyContent: "flex-start",
											flexDirection: "column",
											alignItems: "flex-start",
											px: 1,
											pr:2,
											position: "relative",
											m:0
										}}>
											<div
												className={
													errorMessageInputCultura ? styles.errControl : styles.noErrControl
												}
											>
												<Typography 
													variant="caption" 
													display="block" 
													gutterBottom 
													sx={{
														top:-25,
														position:"absolute",
														px:1,
														backgroundColor:"white",
														visibility:(orderItem.propriedadeCultivo ? "visible" : "hidden"),
														transition:"all 1s ease"
													}}
												>
													Selecionar Cultura/Variedade *
												</Typography>
												<ThemeProvider theme={theme}>
													<Autocomplete
														options={allFarms.sort(
															(a, b) => -b.firstLetter.localeCompare(a.firstLetter)
														)}
														groupBy={(option) => option.firstLetter}
														getOptionLabel={(option) => option.title}
														sx={{ width: "100%", border: "none" }}
														value={orderItem.propriedadeCultivo}
														onChange={(event, newValue) => {
															if(newValue.id !== orderItem.propriedadeCultivoId){
																setOrderItem({
																	...orderItem,
																	propriedadeCultivoId: newValue.id, 
																	propriedadeCultivo: newValue,
																	embalagem: null,
																	embalagemId: null
																});
															}
														}}
														renderInput={(params) => (
															<div ref={params.InputProps.ref}>
																<input
																	type="text"
																	{...params.inputProps}
																	placeholder="Selecionar Cultura/Variedade *"
																/>
															</div>
														)}
													/>
												</ThemeProvider>
											</div>
										</Box>
									</Grid>
									<Grid item xs={12} sm={6} md={6}>
										<Box	Box sx={{
											width: "100%",
											display: "flex",
											justifyContent: "flex-start",
											flexDirection: "column",
											alignItems: "flex-start",
											px: 1,
											pr:2,
											position: "relative",
											m:0
										}}>
											<Typography 
												variant="caption" 
												display="block" 
												gutterBottom 
												sx={{
													top:-25,
													position:"absolute",
													px:1,
													backgroundColor:"white",
													visibility:(orderItem.preco ? "visible" : "hidden"),
													transition:"all 1s ease"
												}}
											>
												Preço
											</Typography>
											<div className={styles.noErrControl}>
												<input
													type="number"
													placeholder="Preço"
													value={orderItem.preco}
													onChange={(event) => setOrderItem({...orderItem, preco: event.target.value})}
												/>
											</div>
										</Box>
									</Grid>
									<Grid item xs={12} sm={6} md={6}>
										<Box	Box sx={{
											width: "100%",
											display: "flex",
											justifyContent: "flex-start",
											flexDirection: "column",
											alignItems: "flex-start",
											px: 1,
											pr:2,
											position: "relative",
											m:0
										}}>
											<Typography 
												variant="caption" 
												display="block" 
												gutterBottom 
												sx={{
													top:-25,
													position:"absolute",
													px:1,
													backgroundColor:"white",
													visibility:(orderItem.embalagem ? "visible" : "hidden"),
													transition:"all 1s ease"
												}}
											>
												Selecione a embalagem *
											</Typography>
											<div
												className={
													errorMessageInputEmbalagem ? styles.errControl : styles.noErrControl
												}
											>
												<ThemeProvider theme={theme}>
													<Autocomplete
														value={orderItem.embalagem}
														onChange={(event, newValue) => {
															setOrderItem({...orderItem, embalagem: newValue, embalagemId: newValue.id});
															getHarvests(orderItem.propriedadeCultivoId, newValue.unidadeMedidaID);
														}}
														options={orderItem.propriedadeCultivo?.embalagens ?? []}
														sx={{ width: "100%", border: "none" }}
														renderInput={(params) => (
															<div ref={params.InputProps.ref}>
																<input
																	type="text"
																	{...params.inputProps}
																	placeholder="Selecione a embalagem *"
																/>
															</div>
														)}
													/>
												</ThemeProvider>
											</div>
										</Box>
									</Grid>
									<Grid item xs={12} sm={12} md={6}>
										<Box	Box sx={{
											width: "100%",
											display: "flex",
											justifyContent: "flex-start",
											flexDirection: "column",
											alignItems: "flex-start",
											px: 1,
											pr:2,
											position: "relative",
											m:0
										}}>
														
											<Typography 
												variant="caption" 
												display="block" 
												gutterBottom 
												sx={{
													top:-25,
													position:"absolute",
													px:1,
													backgroundColor:"white",
													visibility:(orderItem.dataEmbalagem ? "visible" : "hidden"),
													transition:"all 1s ease"
												}}
											>
													Data de embalagem *
											</Typography>
											<div style={{width: "100%"}}>
												<InputData 
													classN={styles.inputRequest}
													placeholder="Data de embalagem *" 
													value={orderItem.dataEmbalagem ? new Date(orderItem.dataEmbalagem ).toLocaleDateString("pt-BR") : null} 
													onDateChange={(date) => setOrderItem({...orderItem, dataEmbalagem: new Date(date)})} 
													// classN={styles.inputManagement}
												/>
											</div>
											{/* <div>
												<InputData
													placeholder="Data de embalagem"
													onDateChange={(date) => setOrderItem({...orderItem, dataEmbalagem: date})}
													withPortal={true}
												/>
											</div> */}
										</Box>
									</Grid>
								</Grid>
							</Grid>
							{(orderItem.embalagemId && orderItem.propriedadeCultivoId) && <Grid item xs={12} sm={12} md={12}>
								<Item sx={{ border: "none", boxShadow: "none" }}>
									<Grid container rowSpacing={2} columnSpacing={{ xs: 0, sm: 0, md: 0 }}>
										<Grid item xs={12} sm={4} md={4} >	
											<Grid item xs={12} sm={12} md={12}>
												<Item sx={stylesItem}>
													<div className={styles.registrationTitle}>
														<a
															onClick={handleOpenLote}
															className={
																styles.addBtn
															}
														>
															Adicionar lotes
															<AddIcon style={styleAdd} />
														</a>
														{errorAddHarvest ? (
															<p className={styles.errText}>Adicione pelo menos um lote</p>
														) : (
															""
														)}
													</div>
												</Item>
											</Grid>
										</Grid>
									</Grid>
								</Item>
							</Grid>}

							<Grid item xs={12} sm={12} md={12}>
								<Item sx={{ minHeight: "45px" }}>
									<Grid
										container
										rowSpacing={0}
										columnSpacing={{ xs: 0, sm: 0, md: 0 }}
										sx={{
											display: "flex",
											alignItems: "center",
											justifyContent: "start",
											width: "100%",
										}}
									>
										{orderItem.harvests.map((item, index) => (
											<Grid item key={index}>
												<Item sx={{ border: "none", boxShadow: "none" }}>
													<div className={styles.itemNameCuture}>
														<button
															className={styles.itemButtonCuture}
															type="button"
															id={item.itemId}
															onClick={() => removeModalHarvest(index)}
														>
															X
														</button>
														{item.codigo + " - " + item.quantidade}
													</div>
												</Item>
											</Grid>
										))}
									</Grid>
								</Item>
							</Grid>
							<Grid item xs={12} sm={12} md={12}>
								<Item sx={{ border: "none", boxShadow: "none" }}>
									<Grid
										container
										rowSpacing={0}
										columnSpacing={{ xs: 0, sm: 0, md: 0 }}
										sx={{
											display: "flex",
											alignItems: "center",
											justifyContent: "end",
											flexDirection: {
												xs: "column-reverse",
												sm: "column-reverse",
												md: "row",
											},
										}}
									>
										<Grid item xs={12} sm={12} md={3}>
											<Item sx={{ border: "none", boxShadow: "none" }}>
												<button
													type="button"
													onClick={handleRemoveAll}
													className={styles.registrationButtonCancelar}
												>
													Cancelar
												</button>
											</Item>
										</Grid>
										<Grid item xs={12} sm={12} md={3}>
											<Item sx={{ border: "none", boxShadow: "none" }}>
												<button
													className={styles.registrationButtoncadastrar}
													type="button"
													onClick={handleCloseBtnAdd}
												>
													Cadastrar
												</button>
											</Item>
										</Grid>
									</Grid>
								</Item>
							</Grid>
						</Grid>
					</Box>
				</Modal>
				<Stack spacing={2} sx={{ width: "100%" }}>
					<Snackbar
						open={openAlertModal}
						autoHideDuration={6000}
						onClose={handleCloseAlertModal}
					>
						<Alert
							onClose={handleCloseAlertModal}
							severity={typeMessage}
							sx={{ width: "100%" }}
						>
							{message}
						</Alert>
					</Snackbar>
				</Stack>
				<Modal
					open={openLote}
					onClose={handleCloseLote}
					aria-labelledby="parent-modal-title"
					aria-describedby="parent-modal-description"
				>
					<Box
						sx={{
							...style,
							width: { xs: "90%", sm: "80%", md: "80%" },
							bgcolor: "white",
							borderRadius: "8px",
							p: 3,
						}}
					>
						<Grid
							container
							rowSpacing={0}
							columnSpacing={{ xs: 0, sm: 2, md: 3 }}
							sx={stylesItem}
							className={styles.registrationInputModal}
						>
							<Grid item xs={12} sm={12} md={12}>
								<div style={{height: 100}}>
									<Grid container item xs={12} sm={12} md={12}>
										<Grid item xs={2} sm={2} md={1}>
											<h2>
												<b>Lotes</b>
											</h2>
										</Grid>
										<Grid item xs={10} sm={10} md={11}>
											<HelpButtonInput
												title={"Tabela de Lotes"}
												text={"Clique em cima do item desejado para seleciona-lo e preencha a quantidade."}
											/>
										</Grid>
									</Grid>
									<p
										className={errorMessageLote ? styles.errText : styles.noErrText}
									>
											Quantidade adicionada no lote maior que disponível
									</p>
								</div>
							</Grid>
							<Grid item xs={12} sm={12} md={12}>
								<Paper sx={{ width: "100%", p: 1, m: 0 }}>
									<ThemeProvider theme={theme}>
										<TableContainer sx={{ maxHeight: "46vh" }}>
											<Table stickyHeader>
												<EnhancedTableHead
													numSelected={selected.length}
													order={orderBy}
													orderBy={orderByColumn}
													onSelectAllClick={handleSelectAllClicks}
													onRequestSort={handleRequestSort}
													rowCount={harvests.length}
													columns={keysForActiveLote}
												/>
												<TableBody>
													{
														//cria uma linha por objeto
														visibleRows
															.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
															.map((row, index) => {
																const isItemSelected = isSelected(row.id);
																return (
																	<TableRow
																		hover
																		role="checkbox"
																		aria-checked={isItemSelected}
																		tabIndex={-1}
																		key={index}
																	>
																		<TableCell padding="checkbox">
																			<Checkbox
																				color="success"
																				checked={isItemSelected}
																				id={row.id}
																				onClick={(event) => handleClick(event, row.id)}
																			/>
																		</TableCell>
																		{keysForActiveLote.map((column) => {
																			return (
																				<TableCell
																					key={column.id}
																					id={row.id}
																					onClick={(event) =>
																						column.id === "quantity" ? "" : handleClick(event, row.id)
																					}
																				>
																					{row[column.id]}
																				</TableCell>
																			);
																		})}
																	</TableRow>
																);
															})
													}
												</TableBody>
											</Table>
										</TableContainer>
										<TablePagination
											rowsPerPageOptions={[7, 14, 21, { label: "Todos", value: Number.MAX_VALUE }]}
											component="div"
											colSpan={7}
											count={visibleRows.length}
											rowsPerPage={rowsPerPage}
											page={page}
											labelRowsPerPage="Linhas por pagina"
											SelectProps={{
												inputProps: {
													"aria-label": "rows per page",
												},
												native: false,
											}}
											onPageChange={handleChangePage}
											onRowsPerPageChange={handleChangeRowsPerPage}
											ActionsComponent={TablePaginationActions}
										/>
									</ThemeProvider>
								</Paper>
							</Grid>

							<Grid item xs={12} sm={12} md={12}>
								<Item sx={stylesItem}>
									<Grid
										container
										rowSpacing={1}
										columnSpacing={{ xs: 0, sm: 0, md: 0 }}
										sx={{
											display: "flex",
											alignItems: "center",
											justifyContent: "end",
											flexDirection: {
												xs: "column-reverse",
												sm: "column-reverse",
												md: "row",
											},
											pt: 2,
										}}
									>
										<Grid item xs={12} sm={12} md={3}>
											
											<button
												type="button"
												onClick={handleCloseLote}
												className={styles.registrationButtonCancelar}
											>
													Cancelar
											</button>
											
										</Grid>
										<Grid item xs={12} sm={12} md={3}>
											<Item sx={{ border: "none", boxShadow: "none" }}>
												<button
													className={styles.registrationButtoncadastrar}
													type="button"
													onClick={registerModalHarvests}
												>
													Cadastrar
												</button>
											</Item>
										</Grid>
									</Grid>
								</Item>
							</Grid>
						</Grid>
					</Box>
				</Modal>
			</div>
		);
}

export default RequestsCad;
