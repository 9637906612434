import { ExpandLess, ExpandMore } from "@mui/icons-material";
import Collapse from "@mui/material/Collapse";
import List from "@mui/material/List";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import * as React from "react";
import * as I from "../../../IconsImport";
import AsideMenuListItem from "../asideMenuListItem/asideMenuListItem";

//import css modules
import { useEffect, useState } from "react";
import styles from "./AsideMenuList.module.css";

export default function AsideMenuList(props) {
	const [open, setOpen] = useState(props.open[0]);
	const [disabledPropriedades, setDisabledPropriedades] = useState(true);
	const [disabledUsuarios, setDisabledUsuarios] = useState(true);
	const [disabledCulturas, setDisabledCulturas] = useState(true);
	const [disabledServicos, setDisabledServicos] = useState(true);
	const [disabledClientes, setDisabledClientes] = useState(true);
	const [disabledInsumos, setDisabledInsumos] = useState(true);
	const [disabledSafras, setDisabledSafras] = useState(true);
	const [disabledManejo, setDisabledManejo] = useState(true);
	const [disabledPedidos, setDisabledPedidos] = useState(true);
	const [disabledPerdas, setDisabledPerdas] = useState(true);
	const [disabledOcorrencias, setDisabledOcorrencias] = useState(true);
	const [disabledColheitas, setDisabledColheitas] = useState(true);
	const [disabledRelatorios, setDisabledRelatorios] = useState(true);
	const [disabledEstoque, setDisabledEstoque] = useState(true);
	useEffect(() => {	
		initData();		
	}, []);

	const hasPermissionGranted = (key) => {
		const user = JSON.parse(localStorage.getItem("user"));
		return (user[key] === true || user[key] === "true");
	};

	const initData = async () => {
		if(hasPermissionGranted("hasPropertyPermission")) setDisabledPropriedades(false);
		if(hasPermissionGranted("hasUserPermission")) setDisabledUsuarios(false);
		if(hasPermissionGranted("hasFarmPermission")) setDisabledCulturas(false);
		if(hasPermissionGranted("hasServicePermission")) setDisabledServicos(false);
		if(hasPermissionGranted("hasCustomerPermission")) setDisabledClientes(false);
		if(hasPermissionGranted("hasInputPermission")) setDisabledInsumos(false);
		if(hasPermissionGranted("hasCropSeasonPermission")) setDisabledSafras(false);
		if(hasPermissionGranted("hasManagementPermission")) setDisabledManejo(false);
		if(hasPermissionGranted("hasOrderPermission")) setDisabledPedidos(false);
		if(hasPermissionGranted("hasHarvestPermission")) setDisabledColheitas(false);
		if(hasPermissionGranted("hasLossPermission")) setDisabledPerdas(false);
		if(hasPermissionGranted("hasIncidentPermission")) setDisabledOcorrencias(false);
		if(hasPermissionGranted("hasReportPermission")) setDisabledRelatorios(false);
		if(hasPermissionGranted("hasStockPermission")) setDisabledEstoque(false);
	};
	
	const handleClick = (newOpen) => {
		if (open !== newOpen)
			setOpen(newOpen); //se o item estiver aberto, fecha, se não, Abreve
		else setOpen("");
	};
	//determina o subItem que ira ficar sob o focus
	const setClassItemFocus = (pag) => {
		if (props.open[1] === pag) return true; //se o subItem estiver aberto, retorna true
	};

	const listStyle = {
		width: "100%",
		// padding: "0px 10px",
		bgcolor: "#00A69A",
		color: "white",
	};

	return (
		<List
			sx={listStyle}
			component="nav"
			className={styles.AsideMenuListDrop}
			aria-labelledby="nested-list-subheader"
		>
			{/*Cadastros */}
			<div className={styles.containerSublist}>
				<ListItemButton
					className={styles.containerSublistDrop}
					onClick={() => handleClick("register")}
				>
					<ListItemText primary="Cadastros" />
					{open === "register" ? <ExpandLess /> : <ExpandMore />}
				</ListItemButton>
				<Collapse in={open === "register"} timeout="auto" unmountOnExit>
					<List component="div" disablePadding>
						<AsideMenuListItem
							nome="containerSubListItem"
							classItemStyle={setClassItemFocus("property")}
							href="/propriedade"
							icon={<I.IconProperty />}
							text="Propriedades"
							disabled={disabledPropriedades}
						/>
						<AsideMenuListItem
							nome="containerSubListItem"
							classItemStyle={setClassItemFocus("users")}
							href="/usuarios"
							icon={<I.IconUsers />}
							text="Usuários"
							disabled={disabledUsuarios}
						/>
						<AsideMenuListItem
							nome="containerSubListItem"
							classItemStyle={setClassItemFocus("culture")}
							href="/culturas"
							// icon={<I.IconCulture />}
							icon={<I.IconCultura />}
							text="Culturas"
							disabled={disabledCulturas}
						/>
						<AsideMenuListItem
							nome="containerSubListItem"
							classItemStyle={setClassItemFocus("services")}
							href="/servicos"
							icon={<I.IconServices />}
							text="Serviços"
							disabled={disabledServicos}
						/>
						<AsideMenuListItem
							nome="containerSubListItem"
							classItemStyle={setClassItemFocus("client")}
							href="/clientes"
							icon={<I.IconClientes />}
							text="Clientes"
							disabled={disabledClientes}
						/>
						<AsideMenuListItem
							nome="containerSubListItem"
							classItemStyle={setClassItemFocus("insumos")}
							href="/insumos"
							icon={<I.IconInsumo />}
							text="Insumos"
							disabled={disabledInsumos}
						/>
					</List>
				</Collapse>
			</div>
			{/* Lançametos */}
			<div className={styles.containerSublist}>
				<ListItemButton
					className={styles.containerSublistDrop}
					onClick={() => handleClick("launch")}
				>
					<ListItemText primary="Lançamentos" />
					{open === "launch" ? <ExpandLess /> : <ExpandMore />}
				</ListItemButton>
				<Collapse in={open === "launch"} timeout="auto" unmountOnExit>
					<List component="div" disablePadding>
						<AsideMenuListItem
							nome="containerSubListItem"
							classItemStyle={setClassItemFocus("safra")}
							href="/safra"
							icon={<I.IconSafras />}
							text="Safras"
							disabled={disabledSafras}
						/>
						<AsideMenuListItem
							nome="containerSubListItem"
							classItemStyle={setClassItemFocus("management")}
							href="/manejo"
							icon={<I.IconAtividade />}
							text="Manejo"
							disabled={disabledManejo}
						/>
						<AsideMenuListItem
							nome="containerSubListItem"
							classItemStyle={setClassItemFocus("requests")}
							href="/pedidos"
							icon={<I.IconPedidos />}
							text="Pedidos"
							disabled={disabledPedidos}
						/>
						<AsideMenuListItem
							nome="containerSubListItem"
							classItemStyle={setClassItemFocus("losses")}
							href="/perdas"
							icon={<I.IconLosts />}
							text="Perdas"
							disabled={disabledPerdas}
						/>
						<AsideMenuListItem
							nome="containerSubListItem"
							classItemStyle={setClassItemFocus("occurrences")}
							href="/ocorrencias"
							icon={<I.IconOccurrences />}
							text="Ocorrências"
							disabled={disabledOcorrencias}
						/>
						<AsideMenuListItem
							nome="containerSubListItem"
							classItemStyle={setClassItemFocus("harvest")}
							href="/colheita"
							icon={<I.IconColheita />}
							text="Colheitas"
							disabled={disabledColheitas}
						/>
						{/* <AsideMenuListItem
							nome="containerSubListItem"
							classItemStyle={setClassItemFocus("atividades")}
							href="/atividades"
							icon={<I.IconActivities />}
							text="Atividades"
						/> */}
					</List>
				</Collapse>
			</div>
			{/* Consultas */}
			<div className={styles.containerSublist}>
				<ListItemButton
					className={styles.containerSublistDrop}
					onClick={() => handleClick("Query")}
				>
					<ListItemText primary="Consultas" />
					{open === "Query" ? <ExpandLess /> : <ExpandMore />}
				</ListItemButton>
				<Collapse in={open === "Query"} timeout="auto" unmountOnExit>
					<List component="div" disablePadding>
						<AsideMenuListItem
							nome="containerSubListItem"
							classItemStyle={setClassItemFocus("tag")}
							href="/etiquetas"
							icon={<I.IconReport />}
							text="Etiquetas"
							disabled={disabledPedidos}
						/>
						<AsideMenuListItem
							nome="containerSubListItem"
							classItemStyle={setClassItemFocus("report")}
							href="/relatorios"
							icon={<I.IconReport />}
							text="Relatórios"
							disabled={disabledRelatorios}
						/>
						<AsideMenuListItem
							nome="containerSubListItem"
							classItemStyle={setClassItemFocus("stock")}
							href="/estoque"
							icon={<I.IconEstoque />}
							text="Estoque"
							disabled={disabledEstoque}
						/>
					</List>
				</Collapse>
			</div>
		</List>
	);
}
