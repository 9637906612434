/* eslint-disable indent */
import React, { useEffect, useMemo, useState } from "react";
import TopMenu from "../../components/TopMenu";
import styles from "./Tag.module.css";
import "../CommonStyle/style.css";
import { Box, createTheme, Dialog, DialogActions, DialogContent, DialogContentText, Grid, IconButton, Modal, Paper, Slide, Table, TableBody, TableCell, TableContainer, TableHead, TablePagination, TableRow, TableSortLabel, ThemeProvider } from "@mui/material";
import { styled } from "@mui/material/styles";
import Search from "../../components/SearchHeader";
import "@react-pdf-viewer/core/lib/styles/index.css";
import HelpButtonInput from "../../components/HelpButtonInput";
import { ptBR } from "@mui/material/locale";
import { useTheme } from "@emotion/react";
import KeyboardArrowLeft from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRight from "@mui/icons-material/KeyboardArrowRight";
import LastPageIcon from "@mui/icons-material/LastPage";
import FirstPageIcon from "@mui/icons-material/FirstPage";
import { visuallyHidden } from "@mui/utils";
import PropTypes from "prop-types";
import { captalizeFirstLetterEacheWord, descendingComparator } from "../../utils/utils";
import { OrderService } from "../../service/order_service";
import { PDFViewer } from "@react-pdf/renderer";
// import PdfHangTags12x5 from "../../components/PdfQrcodeLayout/pdfQrcodeComponents/PdfHangTags12x5";
// import PdfHangTags16x5 from "../../components/PdfQrcodeLayout/pdfQrcodeComponents/PdfHangTags16x5";
import PdfHangTags6x4 from "../../components/PdfQrcodeLayout/pdfQrcodeComponents/PdfHangTags6x4";
// import PdfHangTags6x8 from "../../components/PdfQrcodeLayout/pdfQrcodeComponents/PdfHangTags6x8";
// import PdfHangTags10x10 from "../../components/PdfQrcodeLayout/pdfQrcodeComponents/PdfHangTags10x10";
// import PdfHangTags9x3 from "../../components/PdfQrcodeLayout/pdfQrcodeComponents/PdfHangTags9x3";

//material ul imports
const theme = createTheme(
	{
		palette: {
			primary: { main: "#1976d2" },
		},
	},
	ptBR
);

const style = {
	position: "absolute",
	top: "50%",
	left: "50%",
	width: 100,
	height: "70vh",
	maxHeight: "80vh",
	transform: "translate(-50%, -50%)",
};

const stylesItem = {
	bgcolor: "rgba(0, 0, 0, 0)",
	border: "none",
	boxShadow: "none",
	display: "flex",
	alignItems: "stretch",
	justifyContent: "stretch",
	widths: "auto",
	m: 0,
	p: 0,
	minHeight: "45px",
	minWidth: "40vw",
};

const Item = styled(Paper)(({ theme }) => ({
	backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
	...theme.typography.body2,
	padding: theme.spacing(1),
	textAlign: "center",
	color: theme.palette.text.secondary,
}));

function TablePaginationActions(props) {
	const theme = useTheme();
	const { count, page, rowsPerPage, onPageChange } = props;

	const handleFirstPageButtonClick = (event) => {
		onPageChange(event, 0);
	};

	const handleBackButtonClick = (event) => {
		onPageChange(event, page - 1);
	};

	const handleNextButtonClick = (event) => {
		onPageChange(event, page + 1);
	};

	const handleLastPageButtonClick = (event) => {
		onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
	};

	return (
		<Box sx={{ flexShrink: 0, ml: 2.5 }}>
			<IconButton
				onClick={handleFirstPageButtonClick}
				disabled={page === 0}
				aria-label="first page"
			>
				{theme.direction === "rtl" ? <LastPageIcon /> : <FirstPageIcon />}
			</IconButton>
			<IconButton
				onClick={handleBackButtonClick}
				disabled={page === 0}
				aria-label="previous page"
			>
				{theme.direction === "rtl" ? <KeyboardArrowRight /> : <KeyboardArrowLeft />}
			</IconButton>
			<IconButton
				onClick={handleNextButtonClick}
				disabled={page >= Math.ceil(count / rowsPerPage) - 1}
				aria-label="next page"
			>
				{theme.direction === "rtl" ? <KeyboardArrowLeft /> : <KeyboardArrowRight />}
			</IconButton>
			<IconButton
				onClick={handleLastPageButtonClick}
				disabled={page >= Math.ceil(count / rowsPerPage) - 1}
				aria-label="last page"  
			>
				{theme.direction === "rtl" ? <FirstPageIcon /> : <LastPageIcon />}
			</IconButton>
		</Box>
	);
}
const Transition = React.forwardRef(function Transition(props, ref) {
	return <Slide direction="up" ref={ref} {...props} />;
});

function EnhancedTableHead(props) {
	const {  order, orderBy,  onRequestSort,columns} =
    props;
	const createSortHandler = (property) => (event) => {
		onRequestSort(event, property);
	};

	return (
		<TableHead className={styles.tableGenerator}>			
			<TableRow>
				{columns.map((headCell) => (
					<TableCell
						key={headCell.id}
						align={ "left"}
						padding={ "normal"}
						sortDirection={orderBy === headCell.id ? order : false}
					>
						<TableSortLabel
							active={orderBy === headCell.id}
							direction={orderBy === headCell.id ? order : "asc"}
							onClick={createSortHandler(headCell.id)}
						>
							{headCell.label}
							{orderBy === headCell.id ? (
								<Box component="span" sx={visuallyHidden}>
									{order === "desc" ? "sorted descending" : "sorted ascending"}
								</Box>
							) : null}
						</TableSortLabel>
					</TableCell>
				))}
				<TableCell></TableCell>
			</TableRow>
		</TableHead>
	);
}

EnhancedTableHead.propTypes = {
	onRequestSort: PropTypes.func.isRequired,
	order: PropTypes.oneOf(["asc", "desc"]).isRequired,
	orderBy: PropTypes.string.isRequired,
	rowCount: PropTypes.number.isRequired,
};

const columnForOrder = [
	{
		id: "customerName",
		label:"Cliente",
	},
	{
		id: "orderNumber",
		label:"Número do pedido",
	},
	{
		id: "fiscalNote",
		label:"Nota fiscal",
	},
	{
		id: "expeditionDate",
		label:"Data de expedição",
	},
];

const columnsForOrderItem = [
	{
		id:"farm",
		label: "Cultura",
	},
	{
		id:"lote",
		label: "Lote",
	},
	{
		id:"quantity",
		label:"Quantidade",
	},
	{
		id:"unity",
		label: "Unidade de Medida",
	},
	{
		id:"farmPackage",
		label:"Embalagem",
	},
	{
		id:"farmPackageDate",
		label:"Data de embalagem",
	},
];

function Tag() {
	const keysForOrder = columnForOrder; 
	const keysForOrderItem = columnsForOrderItem; 

	const [selectedOrder, setSelectedOrder] = useState(null);
	const [orderItem, setOrderItem] = useState(null);
	const [size, setSize] = useState("GerarQrcodeEtiqueta6x4");
	const [codeBar, setCodeBar] = useState("0");
	const [distributor, setDistributor] = useState(false);
	const [doubleTag, setDoubleTag] = useState(false);
	const [openOrder, setOpenOrder] = useState(false);
	const [order, setOrder] = useState("asc");
	const [orderBy, setOrderBy] = useState(keysForOrder[0].id);
	const [orders, setOrders] = useState([]);
	const [page, setPage] = React.useState(0); 
	const [rowsPerPage, setRowsPerPage] = React.useState(7);
	const [openOrderDialog, setOpenOrderDialog] = useState(false);
	const [openOrderItem, setOpenOrderItem] = useState(false);
	const [order1, setOrder1] = useState("asc");
	const [orderBy1, setOrderBy1] = useState(keysForOrder[0].id);
	const [orderItems, setOrderItems] = useState([]);
	const [page1, setPage1] = React.useState(0); 
	const [rowsPerPage1, setRowsPerPage1] = React.useState(7);
	const [openOrderItemDialog, setOpenOrderItemDialog] = useState(false);

	const orderService = new OrderService();

	useEffect(() => {
		initData();
	}, []);

	const initData = async () => {
		const propertyId = localStorage.getItem("propertyId");
		
		const orderParams = {
			page: 1,
			limit: 1000,
			sortBy: JSON.stringify(
				{
					createdAt: "desc"
				}
			)
		};

		const ordersInProperty = await orderService.getByProperty(propertyId, orderParams);

		setOrders(ordersInProperty.orders.map((orderInProperty) => {
			return {
				id: orderInProperty.id,
				customertId: orderInProperty.clienteId,
				customerName: orderInProperty.cliente.nome,
				orderNumber: orderInProperty.numeroPedido ?? "Não informado",
				fiscalNote: orderInProperty.nota_fiscal ?? "-",
				expeditionDate: new Date(orderInProperty.data_entrega).toLocaleDateString("pt-BR"),
				itemPedidos: orderInProperty.itemPedidos,
			};
		}));

		if(ordersInProperty.orders.length > 0 ){
			setSelectedOrder({
				id: ordersInProperty.orders[0].id,
				customertId: ordersInProperty.orders[0].clienteId,
				customerName: ordersInProperty.orders[0].cliente.nome,
				orderNumber: ordersInProperty.orders[0].numeroPedido ?? "Não informado",
				fiscalNote: ordersInProperty.orders[0].nota_fiscal ?? "-",
				expeditionDate: new Date(ordersInProperty.orders[0].data_entrega).toLocaleDateString("pt-BR"),
			});

			const currentOrderItems = [];

			ordersInProperty.orders[0].itemPedidos.forEach((item) => {
				item.lotes.forEach((harvest) => {
					console.log(harvest);
					currentOrderItems.push({
						id: item.id,
						lote: harvest.lote.codigo,
						quantity: harvest.quantidade.toString().replace(".",","),
						unity: item.embalagem.unidadeMedida.sigla,
						loteID: harvest.loteId,
						farm: captalizeFirstLetterEacheWord(`${harvest.lote.propriedadeCultivo.cultivo.cultura} ${(harvest.lote.propriedadeCultivo.cultivo.variedade ?? "")}`),
						farmPackageDate: item.data_embalagem ? new Date(item.data_embalagem).toLocaleDateString("pt-BR") : "Não Informado",
						farmPackage: item.embalagem.nome ?? "Não informado",
					});
				});
			});

			setOrderItems(currentOrderItems);
			if(currentOrderItems.length > 0)
				setOrderItem(currentOrderItems[0]);
		}
	};

	const handleRequestSort = (event, property) => {
		const isAsc = orderBy === property && order === "asc";
		setOrder(isAsc ? "desc" : "asc");
		setOrderBy(property);
	};

	const handleOpenOrder = () => {
		setOpenOrder(true); //abre o modal
	};
	const handleCloseOrder = () => {
		setOpenOrder(false); //fecha o modal
	};

	const handleRequestSort1 = (event, property) => {
		const isAsc = orderBy1 === property && order1 === "asc";
		setOrder1(isAsc ? "desc" : "asc");
		setOrderBy1(property);
	};

	const handleOpenOrderItem = () => {
		setOpenOrderItem(true); //abre o modal
	};
	const handleCloseOrderItem = () => {
		setOpenOrderItem(false); //fecha o modal
	};

	function generateTag() {
	}

	const visibleRows = useMemo(
		() =>
			stableSort(orders, getComparator(order, orderBy)),
		[order, orderBy, page, rowsPerPage, orders]
	);

	const visibleRows1 = useMemo(
		() =>
			stableSort(orderItems, getComparator(order1, orderBy1)),
		[order1, orderBy1, page1, rowsPerPage1, orderItems]
	);

	function stableSort(array, comparator) {
		const stabilizedThis = array.map((el, index) => [el, index]);
		stabilizedThis.sort((a, b) => {
			const order = comparator(a[0], b[0]);
			if (order !== 0) 
				return order;
	
			return a[1] - b[1];
		});
		return stabilizedThis.map((el) => el[0]);
	}

	function getComparator(order, orderBy) {
		return order === "desc"
			? (a, b) => descendingComparator(a, b, orderBy)
			: (a, b) => -descendingComparator(a, b, orderBy);
	}

	let dataAllOrders = [];
	let dataAllOrderItems = [];

	const formatDate = (dateToFormat) => {
		const date = new Date(dateToFormat);
		return (
			("0" + date.getUTCDate()).slice(-2) +
			"/" +
			("0" + (date.getUTCMonth() + 1)).slice(-2) +
			"/" +
			date.getUTCFullYear()
		);
	};
	
	orders.map((item, index) => {
		return dataAllOrders.push({
			id: item.id,
			dataColheita: formatDate(item.dataColheita),
			nome: item.nome,
			safra: item.safra,
			propCultura: item.propCultura,
			embalagemId: item.embalagemId,
			index: index,
		});
	});

	orderItems.map((item, index) => {
		return dataAllOrderItems.push({
			id: item.id,
			dataColheita: formatDate(item.dataColheita),
			nome: item.nome,
			safra: item.safra,
			propCultura: item.propCultura,
			embalagemId: item.embalagemId,
			index: index,
		});
	});

	const handleOpenOrderItemDialog = (e) => {
		const currentOrderItems = orderItems.filter((orderItemToTest) => orderItemToTest.loteID = e.target.id);
		if(currentOrderItems.length === 1)
			setOrderItem(currentOrderItems[0]);
		setOpenOrderItemDialog(true); //abre o modal
	};
	
	const handleCloseAddOrderItem = () => {
		setOpenOrderItem(false); //fecha o modal
		setOpenOrderItemDialog(false);
	};

	const handleCloseOrderItemDialog = () => {
		setOrderItem(null);
		setOpenOrderItemDialog(false); //fecha o modal
	};

	const handleChangePage1 = (event, newPage) => {
		setPage1(newPage); //paginação
	};

	const handleChangeRowsPerPage1 = (event) => {
		setRowsPerPage1(+event.target.value); //paginação
		setPage1(0);
	};

	const handleOpenOrderDialog = (e) => {
		const currentOrders = orders.filter((orderToTest) => orderToTest.id = e.target.id);
		if(currentOrders.length === 1) {
			setSelectedOrder(currentOrders[0]);
			const currentOrderItems= [];

			currentOrders[0].itemPedidos.forEach((item) => {
				item.lotes.forEach((harvest) => {
					console.log(harvest);
					currentOrderItems.push({
						id: item.id,
						lote: harvest.lote.codigo,
						quantity: harvest.quantidade.toString().replace(".",","),
						unity: item.embalagem.unidadeMedida.sigla,
						loteID: harvest.loteId,
						farm: captalizeFirstLetterEacheWord(`${harvest.lote.propriedadeCultivo.cultivo.cultura} ${(harvest.lote.propriedadeCultivo.cultivo.variedade ?? "")}`),
						farmPackageDate: item.data_embalagem ? new Date(item.data_embalagem).toLocaleDateString("pt-BR") : "Não Informado",
						farmPackage: item.embalagem.nome ?? "Não informado",
					});
				});
			});

			setOrderItems(currentOrderItems);
			if(currentOrderItems.length > 0)
				setOrderItem(currentOrderItems[0]);
		}
		setOpenOrderDialog(true); //abre o modal
	};
	
	const handleCloseAddOrder = () => {
		setOpenOrder(false); //fecha o modal
		setOpenOrderDialog(false);
	};

	const handleCloseOrderDialog = () => {
		setSelectedOrder(null);
		setOpenOrderDialog(false); //fecha o modal
	};

	const handleChangePage = (event, newPage) => {
		setPage(newPage); //paginação
	};

	const handleChangeRowsPerPage = (event) => {
		setRowsPerPage(+event.target.value); //paginação
		setPage(0);
	};

	return (
		<div>
			<TopMenu open={["Query", "tag"]} />
			<Box component="main" sx={{ width: "100vw", p: 0, mt: 12 }}>
				<Box sx={{ width: "100%", px: 3, height: "100%" }}>
					<Grid container rowSpacing={1} columnSpacing={{ xs: 0, sm: 2, md: 3 }}>
						<Grid item xs={12} sm={12} md={12}>
							<Item
								sx={{
									bgcolor: "rgba(0, 0, 0, 0)",
									border: "none",
									boxShadow: "none",
								}}
							>
								<Search
									title="Etiqueta"
								/>
							</Item>
						</Grid>
						<Grid item xs={6} sm={12} md={6}>
							<Box
								sx={{
									width: "100%",
									height: "100%",
									bgcolor: "white",
									borderRadius: 4,
									p: 3,
									m: 0,
								}}
							>
								<Grid container rowSpacing={3} columnSpacing={3} direction={"column"}>
									<Grid
										item
										xs={12}
										sm={12}
										md={12}
										sx={{
											display: "flex",
											justifyContent: "start",
											alignItems: "center",
										}}
									>
										<p>
											<b>Pedido</b>
										</p>
									</Grid>
			
									<Grid
										item
										xs={12}
										sm={12}
										md={12}
										sx={{
											display: "flex",
											justifyContent: "center",
											alignItems: "center",
										}}
										className={styles.registrationInputModal}
									>
										<Box sx={{
											border: "1px solid rgba(0,0,0,0.3)",
											borderRadius: "8px",
											width: "100%",
											display: "flex",
											justifyContent: "flex-start",
											flexDirection: "row",
											alignItems: "center",
											px: 1,
											pr:2,
											position: "relative",
											m:0
										}}>	
											<div
												className={styles.noErrControl}
											>
												<input
													type="text"
													id="order"
													disabled
													value={selectedOrder?.orderNumber ?? ""}
													className={styles.inputRequestItem}
												>
												</input>
											</div>
											<button
												onClick={handleOpenOrder}
												type="button"
												className={styles.ButtonConfirm}
											>
												Selecionar
											</button>												
										</Box>
									</Grid>

									<Grid
										item
										xs={12}
										sm={12}
										md={12}
										sx={{
											display: "flex",
											justifyContent: "start",
											alignItems: "center",
										}}
									>
										<p>
											<b>Lote</b>
										</p>
									</Grid>
			
									<Grid
										item
										xs={12}
										sm={12}
										md={12}
										sx={{
											display: "flex",
											justifyContent: "center",
											alignItems: "center",
										}}
										className={styles.registrationInputModal}
									>
										<Box sx={{
											border: "1px solid rgba(0,0,0,0.3)",
											borderRadius: "8px",
											width: "100%",
											display: "flex",
											justifyContent: "flex-start",
											flexDirection: "row",
											alignItems: "center",
											px: 1,
											pr:2,
											position: "relative",
											m:0
										}}>	
											<div
												className={styles.noErrControl}
											>
												<input
													type="text"
													id="order"
													disabled
													value={orderItem?.lote ?? ""}
													className={styles.inputRequestItem}
												>
												</input>
											</div>
											<button
												onClick={handleOpenOrderItem}
												type="button"
												className={styles.ButtonConfirm}
											>
												Selecionar
											</button>												
										</Box>
									</Grid>
									<Grid
										item
										xs={12}
										sm={12}
										md={12}
										sx={{
											display: "flex",
											justifyContent: "start",
											alignItems: "center",
										}}
									>
										<p>
											<b>Tamanho</b>
										</p>
									</Grid>
			
									<Grid
										item
										xs={12}
										sm={12}
										md={12}
										sx={{
											display: "flex",
											justifyContent: "center",
											alignItems: "center",
										}}
										className={styles.registrationInputModal}
									>
										<select
											id="size"
											onChange={(event) => setSize(event.target.value)}
											value={size}
										>
											<option value={"GerarQrcodeEtiqueta6x4"}>Etiqueta 6cm x 4cm</option>
											<option value={"GerarQrcodeEtiqueta6x8"}>Etiqueta 6cm x 8cm</option>
											<option value={"GerarQrcodeEtiqueta10x10"}>Etiqueta 10cm x 10cm</option>
											<option value={"GerarQrcodeEtiqueta9x3"}>Etiqueta 9cm x 3cm</option>
											<option value={"GerarQrcodeEtiqueta12x5"}>Etiqueta 12cm x 5cm</option>
											<option value={"GerarQrcodeEtiqueta16x5"}>Etiqueta 16cm x 5cm</option>
											{/* <option value={"GerarQrcodeEtiquetaPersonalizada"}>Etiqueta Personalizada</option> */}
										</select>
									</Grid>
									<Grid
										item
										xs={12}
										sm={12}
										md={12}
										sx={{
											display: "flex",
											justifyContent: "start",
											alignItems: "center",
										}}
									>
										<p>
											<b>Quantidade de codigos de barras</b>
										</p>
									</Grid>
									<Grid
										item
										xs={12}
										sm={12}
										md={12}
										sx={{
											display: "flex",
											justifyContent: "center",
											alignItems: "center",
										}}
										className={styles.registrationInputModal}
									>
										<select
											id="codebar"
											onChange={(event) => setCodeBar(event.target.value)}
											value={codeBar}
										>
											{(size === "GerarQrcodeEtiqueta6x4" ||
												size === "GerarQrcodeEtiqueta6x4Rot" ||
												size === "GerarQrcodeEtiqueta6x8" ||
												size === "GerarQrcodeEtiqueta10x10" ||
												size === "GerarQrcodeEtiqueta12x5" ||
												size === "GerarQrcodeEtiqueta9x3") && (
												<>
													<option value={"0"}>Nenhum</option>
													<option value={"EAN13"}>código de barras do produtor</option>
													<option value={"CODE128"}>código de barras do cliente</option>
												</>
											)}
											{size === "GerarQrcodeEtiqueta16x5" && (
												<>
													<option value={"0"}>Nenhum</option>
													<option value={"EAN13"}>código de barras do produtor</option>
													<option value={"CODE128"}>código de barras do cliente</option>
													<option value={"all"}>código de barras do produtor e do cliente</option>
												</>
											)}
										</select>
									</Grid>
			
									<Grid
										item
										xs={12}
										sm={12}
										md={6}
										sx={{
											display: "flex",
											justifyContent: "start",
											alignItems: "center",
										}}
									>
										<input
											type="checkbox"
											value={distributor}
											onChange={() => setDistributor(!distributor)}
										/>
										<p>
											<b> Informação da distribuidora</b>
										</p>
									</Grid>
			
									<Grid
										item
										xs={12}
										sm={12}
										md={6}
										sx={{
											display: "flex",
											justifyContent: "start",
											alignItems: "center",
										}}
									>
										<input
											type="checkbox"
											value={doubleTag}
											onChange={() => setDoubleTag(!doubleTag)}
										/>
										<p>
											<b> Etiqueta dupla</b>
										</p>
									</Grid>
			
									<Grid item xs={12} sm={12} md={12} sx={{ marginTop: 5 }}>
										<Grid
											container
											rowSpacing={{ xs: 2, sm: 1, md: 0 }}
											columnSpacing={{ xs: 1, sm: 2, md: 3 }}
											display="flex"
											alignItems="center"
											justifyContent="end"
											flexDirection={{
												xs: "column-reverse",
												sm: "column-reverse",
												md: "row",
											}}
										>
											<Grid item xs={12} sm={12} md={12}>
												<button
													className={styles.registrationButtoncadastrar}
													type="button"
													onClick={generateTag}
												>
													Gerar etiqueta
												</button>
											</Grid>
										</Grid>
									</Grid>
								</Grid>
							</Box>
						</Grid>
						<Grid item xs={6} sm={12} md={6}>
							<PDFViewer
								style={{
									width: "100%",
									height: "100%",
									border: "none",
									boxShadow: "none",
									backgroundColor: "#525659",
								}}
							>
								{
									(size === "GerarQrcodeEtiqueta6x4" && 
										<PdfHangTags6x4 
											item={orderItem.loteID} 
											pedido={order.id}

										/>)
								}
							</PDFViewer>
						</Grid>
					</Grid>
				</Box>
			</Box>
			<Modal
				open={openOrder}
				onClose={handleCloseOrder}
				aria-labelledby="parent-modal-title"
				aria-describedby="parent-modal-description"
			>
				<Box
					sx={{
						...style,
						width: { xs: "90%", sm: "50%", md: "50%" },
						bgcolor: "white",
						borderRadius: "8px",
						p: 3,
					}}
				>
					<Grid
						container
						rowSpacing={0}
						columnSpacing={{ xs: 0, sm: 2, md: 3 }}
						sx={stylesItem}
						className={styles.registrationInputModal}
					>
						<Grid item xs={12} sm={12} md={12}>
							<div className={styles.titleTabelaModal}>
								<h2>
									<b>Pedidos</b>
								</h2>
								<HelpButtonInput
									title={"Tabela de Pedidos"}
									text={"Clique em cima do item desejado para seleciona-lo."}
								/>
							</div>
						</Grid>
						<Grid item xs={12} sm={12} md={12}>
							<Paper sx={{ width: "100%", p: 1, m: 0 }}>
								<ThemeProvider theme={theme}>
									<TableContainer sx={{ maxHeight: "46vh" }}>
										<Table stickyHeader>
											<EnhancedTableHead
												order={order}
												orderBy={orderBy}
												onRequestSort={handleRequestSort}
												rowCount={dataAllOrders.length}
												columns={keysForOrder}
											/>
											<TableBody>
												{
													//cria uma linha por objeto
													visibleRows
														.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
														.map((row, index) => {
															return (
																<TableRow hover tabIndex={-1} key={index}>
																	{keysForOrder.map((column) => {
																		return (
																			<TableCell
																				key={column}
																				id={row.id}
																				onClick={handleOpenOrderDialog}
																			>
																				{row[column.id]}
																			</TableCell>
																		);
																	})}
																</TableRow>
															);
														})
												}
											</TableBody>
										</Table>
									</TableContainer>
									<TablePagination
										rowsPerPageOptions={[7, 14, 21, { label: "Todos", value: Number.MAX_VALUE }]}
										component="div"
										colSpan={7}
										count={visibleRows.length}
										rowsPerPage={rowsPerPage}
										page={page}
										labelRowsPerPage="Linhas por pagina"
										SelectProps={{
											inputProps: {
												"aria-label": "rows per page",
											},
											native: false,
										}}
										onPageChange={handleChangePage}
										onRowsPerPageChange={handleChangeRowsPerPage}
										ActionsComponent={TablePaginationActions}
									/>
								</ThemeProvider>
							</Paper>
						</Grid>

						<Grid item xs={12} sm={12} md={12}>
							<Item sx={stylesItem}>
								<Grid
									container
									rowSpacing={1}
									columnSpacing={{ xs: 0, sm: 0, md: 0 }}
									sx={{
										display: "flex",
										alignItems: "center",
										justifyContent: "end",
										flexDirection: {
											xs: "column-reverse",
											sm: "column-reverse",
											md: "row",
										},
										pt: 2,
									}}
								>
									<Grid item xs={12} sm={12} md={3}>
										<Item sx={stylesItem}>
											<button
												type="button"
												onClick={handleCloseOrder}
												className={styles.ButtonCancelarModal}
											>
												Cancelar
											</button>
										</Item>
									</Grid>
								</Grid>
							</Item>
						</Grid>
					</Grid>
					<Dialog
						open={openOrderDialog}
						TransitionComponent={Transition}
						keepMounted
						onClose={handleCloseOrderDialog}
						aria-describedby="alert-dialog-slide-description"
					>
						<DialogContent>
							<DialogContentText id="alert-dialog-slide-description">
								Deseja Selecionar esse pedido?
							</DialogContentText>
						</DialogContent>
						<DialogActions
							sx={{
								display: "flex",

								flexDirection: "row",
							}}
						>
							<button
								className={styles.ButtonCancelarModal}
								onClick={handleCloseOrderDialog}
							>
								Cancelar
							</button>

							<button
								className={styles.ButtonConfimModal}
								onClick={handleCloseAddOrder}
							>
								Selecionar
							</button>
						</DialogActions>
					</Dialog>
				</Box>
			</Modal>
			<Modal
				open={openOrderItem}
				onClose={handleCloseOrderItem}
				aria-labelledby="parent-modal-title"
				aria-describedby="parent-modal-description"
			>
				<Box
					sx={{
						...style,
						width: { xs: "90%", sm: "50%", md: "50%" },
						bgcolor: "white",
						borderRadius: "8px",
						p: 3,
					}}
				>
					<Grid
						container
						rowSpacing={0}
						columnSpacing={{ xs: 0, sm: 2, md: 3 }}
						sx={stylesItem}
						className={styles.registrationInputModal}
					>
						<Grid item xs={12} sm={12} md={12}>
							<div className={styles.titleTabelaModal}>
								<h2>
									<b>Lotes</b>
								</h2>
								<HelpButtonInput
									title={"Tabela de Lotes"}
									text={"Clique em cima do item desejado para seleciona-lo."}
								/>
							</div>
						</Grid>
						<Grid item xs={12} sm={12} md={12}>
							<Paper sx={{ width: "100%", p: 1, m: 0 }}>
								<ThemeProvider theme={theme}>
									<TableContainer sx={{ maxHeight: "46vh" }}>
										<Table stickyHeader>
											<EnhancedTableHead
												order={order1}
												orderBy={orderBy1}
												onRequestSort={handleRequestSort1}
												rowCount={dataAllOrderItems.length}
												columns={keysForOrderItem}
											/>
											<TableBody>
												{
													//cria uma linha por objeto
													visibleRows1
														.slice(page1 * rowsPerPage1, page1 * rowsPerPage1 + rowsPerPage1)
														.map((row, index) => {
															return (
																<TableRow hover tabIndex={-1} key={index}>
																	{keysForOrderItem.map((column) => {
																		return (
																			<TableCell
																				key={column}
																				id={row.loteID}
																				onClick={handleOpenOrderItemDialog}
																			>
																				{row[column.id]}
																			</TableCell>
																		);
																	})}
																</TableRow>
															);
														})
												}
											</TableBody>
										</Table>
									</TableContainer>
									<TablePagination
										rowsPerPageOptions={[7, 14, 21, { label: "Todos", value: Number.MAX_VALUE }]}
										component="div"
										colSpan={7}
										count={visibleRows1.length}
										rowsPerPage={rowsPerPage1}
										page={page1}
										labelRowsPerPage="Linhas por pagina"
										SelectProps={{
											inputProps: {
												"aria-label": "rows per page",
											},
											native: false,
										}}
										onPageChange={handleChangePage1}
										onRowsPerPageChange={handleChangeRowsPerPage1}
										ActionsComponent={TablePaginationActions}
									/>
								</ThemeProvider>
							</Paper>
						</Grid>

						<Grid item xs={12} sm={12} md={12}>
							<Item sx={stylesItem}>
								<Grid
									container
									rowSpacing={1}
									columnSpacing={{ xs: 0, sm: 0, md: 0 }}
									sx={{
										display: "flex",
										alignItems: "center",
										justifyContent: "end",
										flexDirection: {
											xs: "column-reverse",
											sm: "column-reverse",
											md: "row",
										},
										pt: 2,
									}}
								>
									<Grid item xs={12} sm={12} md={3}>
										<Item sx={stylesItem}>
											<button
												type="button"
												onClick={handleCloseOrderItem}
												className={styles.ButtonCancelarModal}
											>
												Cancelar
											</button>
										</Item>
									</Grid>
								</Grid>
							</Item>
						</Grid>
					</Grid>
					<Dialog
						open={openOrderItemDialog}
						TransitionComponent={Transition}
						keepMounted
						onClose={handleCloseOrderItemDialog}
						aria-describedby="alert-dialog-slide-description"
					>
						<DialogContent>
							<DialogContentText id="alert-dialog-slide-description">
								Deseja Selecionar esse lote?
							</DialogContentText>
						</DialogContent>
						<DialogActions
							sx={{
								display: "flex",

								flexDirection: "row",
							}}
						>
							<button
								className={styles.ButtonCancelarModal}
								onClick={handleCloseOrderItemDialog}
							>
								Cancelar
							</button>

							<button
								className={styles.ButtonConfimModal}
								onClick={handleCloseAddOrderItem}
							>
								Selecionar
							</button>
						</DialogActions>
					</Dialog>
				</Box>
			</Modal>
		</div>
	);
}

export default Tag;
