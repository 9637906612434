import React, { useEffect } from "react";

import Seta from "../../../../Assets/images/seta.png";

import {
	Document,
	Font,
	Image,
	Page,
	StyleSheet,
	Text,
	View,
} from "@react-pdf/renderer";

import Roboto from "../../../../Assets/fonts/Roboto-Bold.ttf";

import JsBarcode from "jsbarcode";
import { useState } from "react";
import { OrderService } from "../../../../service/order_service";
import { PropertyService } from "../../../../service/property_service";

Font.register({
	family: "Roboto bold",
	format: "truetype",
	src: Roboto,
});

const styles = StyleSheet.create({
	page: {
	},
	body: {
		marginLeft: "20px",
		paddingRight: "5px",
		width: "100%",
		height: "100%",
		backgroundColor: "white",
		display: "flex",
		alignItems: "center",
		justifyContent: "center",
	},
	containerText: {
		marginBottom: "2px",
		maxWidth: "120px"
	},
	containerTitleText: {
		marginBottom: "3px",
		maxWidth: "120px",
		justifyContent: "center",
	},
	containerTitleCultureText: {
		textTransform: "uppercase",
		marginLeft: "30px",
		marginRight: "30px",
	},
	containerQrcode: {
		marginTop: "30px",
		display: "flex",
		justifyContent: "center",
		alignItems: "center",
		marginRight: "50px",
	},
	qrCode: {
		width: "90px",
	},
	barCode: {
		width: "130px",
	},
	qrCodeContainer: {
		width: "100%",
		height: "100%",
		display: "flex",
		flexDirection: "row",
		justifyContent: "space-between",
		alignItems: "center",
		color: "black",
	},
	qrCodeContainerTexTitle: {
		fontSize: "90px",
		textTransform: "uppercase",
		fontFamily: "Roboto bold",
	},
	qrCodeContainerTextSubTitle: {
		fontSize: "50px",
	},
	footer: {
		position: "absolute",
		width: "100vw",
		height: "13px",
		bottom: 0,
	},
	containerFooter: {
		width: "100vw",
		display: "flex",
		flexDirection: "row",
		justifyContent: "space-between",
		alignItems: "center",
	},
	paggingContainer: {
		width: "100%",
		backgroundColor: "black",
		height: "10px",
		display: "flex",
		flexDirection: "row",
		justifyContent: "space-evenly",
		alignItems: "center",
	},
	paggingText: {
		alignItems: "flex-end",
		fontSize: "4px",
		paddingHorizontal: "5px",
		fontFamily: "Roboto bold",
		color: "white",
	},
	imgFooterInvetida: {
		height: "100%",
		width: "auto",
		transform: "scaleX(-1)",
	},
	imgFooter: {
		height: "100%",
		width: "auto",
	},
	// textTransform
	TextBoldTitle: {
		fontFamily: "Roboto bold",
		fontSize: "13px",
		alignItems: "center",
		justifyContent: "center",
		textTransform: "uppercase",
	},
	TextBoldSubTitle: {
		fontFamily: "Roboto bold",
		fontSize: "9px",
	},
	TextBoldNumber: {
		fontFamily: "Roboto bold",
		fontSize: "14px",
	},
	TextBold: {
		fontFamily: "Roboto bold",
		fontSize: "11px",
	},
	TextNormal: {
		fontSize: "9px",
	},
	TextNormalQrCode: {
		fontSize: "5px",
	},
});

const PdfHangTags = (props) => {
	const orderItemHarvestId = props.item;
	const orderItemId = props.pedido;
	const [property, setProperty] = useState(null);
	const [orderItem, setOrderItem] = useState(null);
	const [harvest, setHarvest] = useState(null);
	const [loading, setLoading] = useState(true);
	const ids = props.ids;
	const [barCodeValor, setBarCodeValor] = useState("000000000000");
	const barCodeType =
		props.codBar1Type !== null ? props.codBar1Type : props.codBar2Type;

	const orderService = new OrderService();
	const propertyService = new PropertyService();

	useEffect(() => {
		initData();
	}, []);

	const formatCpfCnPJ = (value) => {
		if (value.length > 11) {
			value =
				value.substring(0,2) +
				"." +
				value.substring(2, 5) +
				"." +
				value.substring(5, 8) +
				"/" +
				value.substring(8, 12) +
				"." +
				value.substring(12);
		} else {
			value =
				value.substring(0,3) +
				"." +
				value.substring(3,6) +
				"." +
				value.substring(6,9) +
				"-" +
				value.substring(9);
		}
		return value;
	};

	const initData = async () => {
		const propertyId = localStorage.getItem("propertyId");

		const propertyData = await propertyService.getById(propertyId);
		setProperty(propertyData);

		const orderItemData = await orderService.getOrderItem(orderItemId);
		setOrderItem(orderItemData);

		const harvestData = orderItemData.lotes.find((harvestItem) => harvestItem.loteId === orderItemHarvestId);
		setHarvest(harvestData);

		const barCodeCustomer = harvestData.lote.propriedadeCultivo.codigoDeBarraCultivoClientes.find((barCodeCustomerItem) => barCodeCustomerItem.clienteId === orderItemData.pedido.cliente.id);

		if (barCodeType === "CODE128" && barCodeCustomer) {
			if (barCodeCustomer.codigoDeBarra && barCodeCustomer.codigoDeBarra.length < 12) {
				let codeValor = barCodeCustomer.codigoDeBarra.padStart(12, "0");
				setBarCodeValor(codeValor);
			} else {
				setBarCodeValor(barCodeCustomer.codigoDeBarra);
			}
		} else {
			if (harvestData.lote.propriedadeCultivo.codigoDeBarra && harvestData.lote.propriedadeCultivo.codigoDeBarra.length < 12) {
				let codeValor = harvestData.lote.propriedadeCultivo.codigoDeBarra.padStart(12, "0");
				setBarCodeValor(codeValor);
			} else {
				setBarCodeValor(harvestData.lote.propriedadeCultivo.codigoDeBarra);
			}
		}

		setLoading(false);
	};

	const barCodeCanvas = document.createElement("canvas");
	if(barCodeValor && barCodeValor !== ""){
		JsBarcode(barCodeCanvas, barCodeValor, {
			format: barCodeValor?.length === 13 ? "EAN13" : "CODE128", //
			width: 3,
			fontSize: 16,
			height: 120,
		});
	}
	const barcode = barCodeCanvas.toDataURL();

	// const qrCodeCanvas = document.createElement("canvas");
	// QRCode.toCanvas(qrCodeCanvas, "sisagri");
	// const qr = qrCodeCanvas.toDataURL();
	function checkValid() {
		return props.checkDist === "0" ? true : false;
	}

	function checkDoubleTag() {
		return props.doubleTag === "0" ? true : false;
	}

	function QRCodePage({ id }) {
		const dataUrl = document.getElementById(id).toDataURL();

		return (
			<Page
				key={`page_${id}`}
				size={{ width: 283.25, height: checkDoubleTag() ? 566.5 : 220 }}
				style={styles.page}
				orientation="portrait"
			>
				<View style={{height: checkDoubleTag() ? "50%" : "100%"}}>
					<View style={styles.body}>
						<View style={styles.qrCodeContainer}>
							<View>
								<View style={styles.containerTitleCultureText}>
									<View style={{ width: "50px" }}>
									</View>
									<Text style={styles.TextBoldTitle}>
										{harvest.lote.propriedadeCultivo.cultivo.cultura + " - " + (harvest.lote.propriedadeCultivo.cultivo.variedade ?? "")}
									</Text>
								</View>
								<View style={styles.containerTitleText}>
									<Text style={styles.TextBoldSubTitle}>
										Peso Líquido Mínimo: <Text style ={styles.TextBoldNumber}>{`${orderItem?.embalagem.quantidade} ${orderItem?.embalagem.unidadeMedida.sigla}`}</Text>
									</Text>
									<Text style={styles.TextBold}>Código de Rastreio: {`${orderItem?.codigo}-${harvest.lote.codigo}`}</Text>
								</View>
								<View style={styles.containerText}>
									<Text style={styles.TextNormal}>Produzido por: {property.nome}</Text>
									<Text style={styles.TextNormal}>
										{property.cpf_cnpj.length > 11
											? `CNPJ ${formatCpfCnPJ(property.cpf_cnpj)}`
											: `CPF ${formatCpfCnPJ(property.cpf_cnpj)}`}
									</Text>
									<Text style={styles.TextNormal}>
										{property.endereco.endereco + 
											" " + property.endereco.bairro + 
											" - " + property.endereco.cidade}
									</Text>
								</View>
								{checkValid() && (
									<View style={styles.containerText}>
										<Text style={styles.TextNormal}>
											Distribuídor por: {orderItem.pedido.cliente.nome}
										</Text>
										<Text style={styles.TextNormal}>
											{orderItem.pedido.cliente.cpf_cnpj.length > 11
												? `CNPJ ${formatCpfCnPJ(orderItem.pedido.cliente.cpf_cnpj)}`
												: `CPF ${formatCpfCnPJ(orderItem.pedido.cliente.cpf_cnpj)}`}	
										</Text>
										<Text style={styles.TextNormal}>
											{orderItem.pedido.cliente.enderecoCliente.endereco + 
												" " + orderItem.pedido.cliente.enderecoCliente.bairro + 
												" - " + orderItem.pedido.cliente.enderecoCliente.cidade}
										</Text>
									</View>
								)}
								<View style={styles.containerText}>
									{orderItem.data_embalagem && (
										<Text style={styles.TextNormal}>
											Data de Embalagem: {new Date(orderItem.data_embalagem).toLocaleDateString("pt-BR")}
										</Text>
									)}

									<Text style={styles.TextNormal}>
										Data de Entrega: {new Date(orderItem.pedido.data_entrega).toLocaleDateString("pt-BR")}
									</Text>
									{/* <Text style={styles.TextNormal}>{getDate(data_colheita)}</Text> */}
								</View>
							</View>
							{/* lado direito da etiqueta */}
							<View style={styles.containerQrcode}>
								<Text style={styles.TextNormalQrCode}>CONHEÇA ORIGEM</Text>
								<Text style={styles.TextNormalQrCode}>DESTE PRODUTO</Text>
								<Image src={dataUrl} style={styles.qrCode} />

								{barCodeType !== null && barCodeValor && <Image src={barcode} style={styles.barCode} />}
								<Text style={styles.TextNormalQrCode}>POR: SISAGRI</Text>
							</View>
							{/* <View ref={ref} /> */}
						</View>
					</View>
					{/* roda pé  */}
					<View style={styles.footer} fixed>
						<View style={styles.paggingContainer}>
							<Image src={Seta} style={styles.imgFooter} />
							<View style={styles.paggingText}>
								<Text>PRODUTO COM ORIGEM RASTREADA</Text>
							</View>
							<Image src={Seta} style={styles.imgFooterInvetida} />
							<View style={styles.paggingText}>
								<Text>https://www.sisagri.com.br</Text>
							</View>
							<Image src={Seta} style={styles.imgFooterInvetida} />
						</View>
					</View>
				</View>
				{checkDoubleTag() && (
					<View style={{height: checkDoubleTag() ? "50%" : "100%"}}>
						<View style={styles.body}>
							<View style={styles.qrCodeContainer}>
								<View>
									<View style={styles.containerTitleCultureText}>
										<View style={{ width: "50px" }}>
										</View>
										<Text style={styles.TextBoldTitle}>
											{harvest.lote.propriedadeCultivo.cultivo.cultura + " - " + (harvest.lote.propriedadeCultivo.cultivo.variedade ?? "")}
										</Text>
									</View>
									<View style={styles.containerTitleText}>
										<Text style={styles.TextBoldSubTitle}>
											Peso Líquido Mínimo: <Text style ={styles.TextBoldNumber}>{`${orderItem?.embalagem.quantidade} ${orderItem?.embalagem.unidadeMedida.sigla}`}</Text>
										</Text>
										<Text style={styles.TextBold}>Código de Rastreio: {`${orderItem?.codigo}-${harvest.lote.codigo}`}</Text>
									</View>
									<View style={styles.containerText}>
										<Text style={styles.TextNormal}>Produzido por: {property.nome}</Text>
										<Text style={styles.TextNormal}>
											{property.cpf_cnpj.length > 11
												? `CNPJ ${formatCpfCnPJ(property.cpf_cnpj)}`
												: `CPF ${formatCpfCnPJ(property.cpf_cnpj)}`}
										</Text>
										<Text style={styles.TextNormal}>
											{property.endereco.endereco + 
												" " + property.endereco.bairro + 
												" - " + property.endereco.cidade}
										</Text>
									</View>
									{checkValid() && (
										<View style={styles.containerText}>
											<Text style={styles.TextNormal}>
												Distribuídor por: {orderItem.pedido.cliente.nome}
											</Text>
											<Text style={styles.TextNormal}>
												{orderItem.pedido.cliente.cpf_cnpj.length > 11
													? `CNPJ ${formatCpfCnPJ(orderItem.pedido.cliente.cpf_cnpj)}`
													: `CPF ${formatCpfCnPJ(orderItem.pedido.cliente.cpf_cnpj)}`}	
											</Text>
											<Text style={styles.TextNormal}>
												{orderItem.pedido.cliente.enderecoCliente.endereco + 
													" " + orderItem.pedido.cliente.enderecoCliente.bairro + 
													" - " + orderItem.pedido.cliente.enderecoCliente.cidade}
											</Text>
										</View>
									)}
									<View style={styles.containerText}>
										{orderItem.data_embalagem && (
											<Text style={styles.TextNormal}>
												Data de Embalagem: {new Date(orderItem.data_embalagem).toLocaleDateString("pt-BR")}
											</Text>
										)}

										<Text style={styles.TextNormal}>
											Data de Entrega: {new Date(orderItem.pedido.data_entrega).toLocaleDateString("pt-BR")}
										</Text>
									</View>
								</View>
								{/* lado direito da etiqueta */}
								<View style={styles.containerQrcode}>
									<Text style={styles.TextNormalQrCode}>CONHEÇA ORIGEM</Text>
									<Text style={styles.TextNormalQrCode}>DESTE PRODUTO</Text>
									<Image src={dataUrl} style={styles.qrCode} />

									{barCodeType !== null && barCodeValor && <Image src={barcode} style={styles.barCode} />}
									<Text style={styles.TextNormalQrCode}>POR: SISAGRI</Text>
								</View>
								{/* <View ref={ref} /> */}
							</View>
						</View>
						{/* roda pé  */}
						<View style={styles.footer} fixed>
							<View style={styles.paggingContainer}>
								<Image src={Seta} style={styles.imgFooter} />
								<View style={styles.paggingText}>
									<Text>PRODUTO COM ORIGEM RASTREADA</Text>
								</View>
								<Image src={Seta} style={styles.imgFooterInvetida} />
								<View style={styles.paggingText}>
									<Text>https://www.sisagri.com.br</Text>
								</View>
								<Image src={Seta} style={styles.imgFooterInvetida} />
							</View>
						</View>
					</View>
				)}
			</Page>
		);
	}

	return (
		!loading ?
			<Document orientation="portrait">
				{ids.map((id) => (
					<QRCodePage id={id} key={id} />
				))}
			</Document>
			:<Document></Document>
	);
};

export default PdfHangTags;